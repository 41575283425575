import Vue from 'vue'
import App from './App.vue'
import axios from './plugins/axios'

// AppService.runWarnHandler(Vue);
// VUEX
import Vuex from 'vuex';
Vue.use(Vuex);

import { VueperSlides, VueperSlide } from 'vueperslides';
Vue.use(VueperSlides, VueperSlide);

// Router
import router from './router';
Vue.use(router);

// Coach Plugin
import shop from "../plugins/liox-mediathek-shop";
Vue.use(shop, router);

// Gallery Plugin
import gallery from "../plugins/liox-mediathek-gallery";
Vue.use(gallery, router);

// Store
import store from './store/Index';

// Websocket
import websocket from './services/webSocket.service';
Vue.prototype.$ws = websocket;

// Social Sharing
import ShareNetwork from "vue-social-sharing";
Vue.use(ShareNetwork);

// Vuetify
import vuetify from './plugins/vuetify/vuetify';

// VueFileAgent - Upload
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent, VueFileAgentStyles);

// Truncate - "Text abkürzen"
var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);

// Moment - zum konvertieren eines Datums
import moment from 'moment';

// Filter für Datum's Formatierung
const formDate = (date) => moment(new Date(date), "DD.MM.YYYY");
Vue.filter('formatDate', formDate);

// Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
