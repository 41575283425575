import APIService from "@/services/api.service";
import AppService from "@/services/app.service";

class Payment {
  createProviderURL(url, domClass) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `${url}`;
    if (domClass) {
      script.className = `CCBillWidget${domClass}_29362`;
    } else {
      script.className = "";
    }
    return script;
  }

  checkout(comp, providers, currency, callback) {
    providers.forEach((provider) => {
      const payment = `render${provider.type}Checkout`;
      this[payment](comp, provider, currency, callback);
    });
  }

  subscription(comp, providers, payload, callback, host = "getApiHost") {
    providers.forEach((provider) => {
      const payment = `render${provider.type}Subscription`;
      this[payment](comp, provider, payload, callback, host);
    });
  }

  renderPaypalSubscription(comp, provider, payload, callback, host) {
    console.log("hier bin ich before rendering | paymentService");
    if (comp.$store.state.main.config.explicit == true) {
      const script = this.createProviderURL(
        `https://www.paypal.com/sdk/js?client-id=${provider.client_id}&vault=true&intent=subscription`
      );
      script.onload = function () {
        paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "black",
              layout: "vertical",
              label: "pay",
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                plan_id: payload.plan.paypal,
              });
            },
            onApprove: function (data, actions) {
              payload.subscription_id = data.subscriptionID;
              payload.provider = provider.id;
              payload.plan = payload.plan.id;

              comp.$store
                .dispatch("post", {
                  endpoint: "/api/v1/subscriptions",
                  event: payload,
                  message: false,
                  host: host,
                })
                .then((subscription) => {
                  if (
                    callback.length > 0 &&
                    typeof APIService[callback[0]] == "function"
                  ) {
                    APIService[callback[0]](callback[1]);
                  } else {
                    APIService.setResponse({
                      message: "Die Zahlung war erfolgreich!",
                      color: "green",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  APIService.setResponse({
                    message: "Der Administrator darf keine Zahlung durchführen",
                    color: "red",
                  });
                });
            },
          })
          .render("#paypal-subscription-container"); // Renders the PayPal button
      };

      document.head.appendChild(script);
    }
  }

  renderPaypalCheckout(comp, provider, currency, callback) {
    console.log("hier bin ich before rendering | paymentService");
    if (comp.$store.state.main.config.explicit == true) {
      const script = this.createProviderURL(
        `https://www.paypal.com/sdk/js?client-id=${provider.client_id}&currency=${currency}`
      );

      script.onload = function () {
        paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "black",
              layout: "vertical",
              label: "pay",
            },
            createOrder() {
              return comp.$store
                .dispatch("post", {
                  endpoint: "/api/v1/orders",
                  event: { provider: provider.id },
                  message: false,
                })
                .then((order) => order.result.id);
            },
            onApprove(data) {
              return comp.$store
                .dispatch("post", {
                  endpoint: "/api/v1/orders/" + data.orderID + "/capture",
                  event: { provider: provider.id },
                  message: false,
                })
                .then((orderData) => {
                  comp.$store.dispatch("fetch", {
                    endpoint: `/api/v1/users/me`,
                  });
                  comp.$store.dispatch("delete", {
                    endpoint: `/api/v1/cart/`,
                    message: false,
                  });
                  if (
                    callback.length > 0 &&
                    typeof APIService[callback[0]] == "function"
                  ) {
                    APIService[callback[0]](callback[1]);
                  } else {
                    APIService.setResponse({
                      message: "Vielen Dank für deinen Einkauf!",
                      color: "green",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  APIService.setResponse({
                    message: "Der Administrator darf keine Zahlung durchführen",
                    color: "red",
                  });
                });
            },
          })
          .render("#paypal-checkout-container"); // Renders the PayPal button
      };

      document.head.appendChild(script);
    }
  }

  renderCCBillCheckout(comp, provider, currency, callback) {
    var initialPrice = comp.$store.state.main.cart.sum;
    // var userId = comp.$store.state.main?.result.var
    var userId = comp.$store.state.main.user.result.id;
    var currencyCode = 978;
    var initialPeriod = 2;
    comp.$store
      .dispatch("post", {
        endpoint: "/api/v1/orders/createFormDigestSingle",
        event: {
          initialPrice,
          currencyCode,
          initialPeriod,
        },
        message: false,
      })
      .then((response) => {
        const ccbillUrl = `https://api.ccbill.com/wap-frontflex/flexforms/d34e4649-2422-41d9-9f3b-ac8406e34da2?clientSubacc=0001&initialPrice=${initialPrice}&initialPeriod=${initialPeriod}&currencyCode=${currencyCode}&formDigest=${response.hash}&userId=${userId}`;
        const container = document.getElementById("ccbillWidgetContainer");
        container.innerHTML = "";
        const ccbillLink = document.createElement("v-btn");
        ccbillLink.setAttribute("width", "100%");
        ccbillLink.setAttribute("height", "50px");
        ccbillLink.href = ccbillUrl;
        console.log("ccbillUrl", ccbillLink.href);

        const textDiv = document.createElement("div");
        // textDiv.className = 'white--text';
        textDiv.id = "ccbillCheckoutWidgetContainer";
        textDiv.innerHTML = "Mit Kreditkarte bezahlen";
        ccbillLink.onclick = (e) => {
          e.preventDefault();
          AppService.openCenteredPopup({
            url: ccbillUrl,
            title: "CCBill Payment", // document.title possible?
            width: "960px",
            height: "450px",
            // onClose: () => {
            //     // Do something after the popup is closed
            // }
          });
        };
        ccbillLink.appendChild(textDiv);

        container.appendChild(ccbillLink);
      });
  }

  renderCCBillSubscription(comp, provider, payload, currency, callback) {
    console.log(
      "renderCCBillSubscription  | paymentService",
      payload.plan.price
    );
    var initialPrice = payload.plan.price;
    var initialPeriod = 30;
    var recurringPrice = payload.plan.price;
    var recurringPeriod = 30;
    var numRebills = 99;
    var currencyCode = 978;
    var planId = payload.plan.id;
    var userId = comp.$store.state.main.user.result.id;

    comp.$store
      .dispatch("post", {
        endpoint: "/api/v1/orders/createFormDigestSubscription",
        event: {
          initialPrice,
          initialPeriod,
          recurringPrice,
          recurringPeriod,
          numRebills,
          currencyCode,
        },
        message: false,
      })
      .then((response) => {
        const ccbillUrl = `https://api.ccbill.com/wap-frontflex/flexforms/e7ceee25-f46e-487c-a411-b22a994ea9ef?clientSubacc=0001&initialPrice=${initialPrice}&initialPeriod=${initialPeriod}&recurringPrice=${recurringPrice}&recurringPeriod=${recurringPeriod}&numRebills=${numRebills}&currencyCode=${currencyCode}&formDigest=${response.hash}&planId=${planId}&userId=${userId}`;
        const container = document.getElementById(
          "ccbillSubscriptionWidgetContainer"
        );
        container.innerHTML = "";
        const ccbillLink = document.createElement("a");
        ccbillLink.style.color = comp.$vuetify.theme.dark ? "white" : "black";
        ccbillLink.href = ccbillUrl;
        ccbillLink.onclick = (e) => {
          e.preventDefault();
          AppService.openCenteredPopup({
            url: ccbillUrl,
            title: "CCBill Payment", // document.title possible?
            width: "960px",
            height: "450px",
            // onClose: () => {
            //     // Do something after the popup is closed
            // }
          });
        };
        ccbillLink.className =
          "ccbill-payment-button body-2 text-decoration-none";
        ccbillLink.innerHTML = "Mit Kreditkarte zahlen";

        container.appendChild(ccbillLink);
      });
  }

  //     console.log('renderCCBillCheckout')

  //     comp.$store.dispatch('post', { endpoint: '/api/v1/orders/createFormDigest', event: { initialPrice: 1, currencyCode: 978, initialPeriod: 2 } })
  //     .then((response) => {
  //         console.log(("Formdigest from API | paymentService",response.hash))
  //     })

  //     // const script = this.create(`https://js.ccbill.com/v1.9.0/ccbill-advanced-widget.js`);
  //     // document.head.appendChild(script);
  //     // console.log('renderCCBillCheckout')

  //     // script.onload = function () {
  //     //     comp.$store.dispatch('post', { endpoint: '/api/v1/orders/CCBilltoken', event: { provider: provider.id }, message: false })
  //     //     const widget = new ccbill.CCBillAdvancedWidget(provider.client_id);
  //     //     console.log("widget",widget)
  //     //     .then((oauthToken) => {
  //     //         // order.result.id
  //     //         const widget = new ccbill.CCBillAdvancedWidget(provider.client_id)
  //     //         console.log("widget", widget)
  //     //         try {
  //     //             // const result = widget.createPaymentToken(oauthToken, clientAccnum, clientSubacc, clearPaymentInfo, clearCustomerInfo, timeToLive, numberOfUse);
  //     //             const result = widget.createPaymentToken(oauthToken, provider.client_accnum, provider.client_subacc);
  //     //             result.then(
  //     //                 (data) => {
  //     //                     console.log("SUCCESS");
  //     //                     return data.json();
  //     //                 },
  //     //                 (error) => {
  //     //                     console.log("ERROR");
  //     //                     return error.json();
  //     //                 }).then(json => {
  //     //                     console.log("RESULT :[" + JSON.stringify(json) + "]");
  //     //                 }).catch((error) => {
  //     //                     console.error("ERROR2 [" + error + "]");
  //     //                 });
  //     //             console.log(`FINISHED`);
  //     //         } catch (error) {
  //     //             const errors = [];
  //     //             error.forEach(function (item) {
  //     //                 const msg = item.message.split(".");
  //     //                 errors.push(msg[1]);
  //     //             });
  //     //             console.error(`ERROR ` + JSON.stringify(errors));
  //     //             alert("ERROR: Unable to generate Payment Token: " + JSON.stringify(errors));
  //     //         }
  //     //     });
  //     // }
  // };

  renderSumUpSubscription(comp, provider, payload, callback) {
    console.log("renderSumUpSubscription");
  }
}

export default new Payment();
