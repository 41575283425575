<template>
  <div style="margin: auto;">
    <div v-if="step < 5" class="text-center mt-5">
      <v-img class="mb-4" contain height="128" src="@/assets/logo/LogoMark_blue_new.png" />
      <h1 class="display-2">Registrierung</h1>

      <span class="caption">Schritt </span>
      <span v-text="step" class="caption" />
      <span class="caption"> von 3</span>
    </div>
    <v-card class="pa-4 mt-4">
      <v-card-title v-if="step < 3" class="title py-0 mt-4 font-weight-bold text-center">
        <span>{{ currentTitle }}</span>
      </v-card-title>

      <v-form @submit.prevent="registerUser($event)" v-model="valid">

        <!-- In Step 1 are 2 steps deeper inside -->
        <!-- Emailadresse & Passwort festlegen -->
        <v-window v-model="step" class="mx-4">

          <v-window-item :value="1">
    
            <v-text-field 
              label="Wie lautet E-Mail-Adresse"
              :rules="eMailRules"
              prepend-inner-icon="mdi-email-outline"
              name="email"
              class="mt-4"
              required
              outlined 
            />
            
            <div>
              <input type="hidden" name="role" class="form-control" value="ROLE_END_USER">
              <v-text-field 
                v-model="password" 
                label="Password" 
                prepend-inner-icon="mdi-lock" 
                required
                class="mb-n1" 
                name="password" 
                :append-icon="showPwIcon ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="showPwIcon =!showPwIcon"
                :type="showPwIcon ? 'text' : 'password'"
                loading
                outlined 
                :rules="passwordRules"
              >
                <template v-slot:progress>
                  <v-progress-linear :value="progress" :color="color" absolute height="2"></v-progress-linear>
                </template>
              </v-text-field>

              <v-checkbox 
                class="mt-0 pt-0"
                name="Datenschutz" 
                v-model="datenschutzCheckbox" 
                label="Ich bin mit der Speicherung und weiteren Verwendung meiner Daten, sowie mit der Datenschutzerklärung und den AGB für die Nutzung der 'LKF Medithek' einverstanden."
                required
                :rules="[v => !!v || 'Zustimmung erforderlich!']"
              >
                <template v-slot:label>
                  <div class="pa-2 body-2">
                    Ich bin der Speicherung und weiteren Verwendung meiner Daten <br>
                    zur Bereitstellung dieses Dienstes einverstanden und bestätige <br>
                    die AGB von
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a target="_blank" @click="linkTo('Nutzungsbedingungen')">
                          Liox.io
                        </a>
                      </template>
                      Öffnet in einem neuen Fenster
                    </v-tooltip>

                  </div>
                </template>
              </v-checkbox>
            </div>
            <v-row class="justify-end">
              <v-btn 
                type="submit" 
                color="success" 
                outlined
                :disabled="!valid" 
                class="mb-4 mr-4"
              >
                weiter
              </v-btn>
            </v-row>
          </v-window-item>

          <v-window-item :value="3">
            <v-card v-if="succesfullCreated == true" width="450px" class="pa-4 text--center" color="success">
              <h1 class="white--text">Danke!</h1>
              <p class="white--text">Dein Account ist aktiviert.</p>
              <p class="white--text">Du kannst dich direkt anlegen.</p>
            </v-card>

            <v-card v-else width="450px" class="pa-4" color="error">
              <h4>Es ist ein Fehler aufgetreten.</h4>
              <p>{{ this.errorMessage }}</p>
              <v-btn outlined @click="step = 1">
                E-Mailadresse ändern
              </v-btn>
            </v-card>

          </v-window-item>

        </v-window>

      </v-form>
    </v-card>


    <div class="d-flex justify-center">
      <v-btn @click="linkTo('')" text small class="mt-4 grey--text">zurück zum Login</v-btn>
    </div>
  </div>
</template>

<script>
import APIService from '@/services/api.service';
import userRole from '@/services/userRoleService';
import { mapGetters } from 'vuex';
// import response from '@/components/response/response';


const google = () => import('@/components/sso/google')
const facebook = () => import('@/components/sso/facebook')

export default {
  name: 'registerForm',
  components: {
    response: () => import('@/components/response/response'),
  },
  data() {
    return {
      el: 1,
      step: 1,
      step_inside: 1,
      email: '',
      loading: false,
      valid: true,
      password: '',
      Datenschutz: '',
      datenschutzCheckbox: false,
      showPwIcon: false,
      eMailRules: [
        v => !!v || 'Die E-Mailadresse ist erforderlich.',
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Falsches Format für eine E-Mailadresse'
      ],
      emailIsValid: Boolean,
      passwordIsValid: Boolean,
      valid: false,
      appName: '',
      customerLogo: '',
      errorMessage: '',
      succesfullCreated: false,
    }
  },

  computed: {
    ...mapGetters(["get"]),
    progress() {
      return Math.min(100, this.password.length * 16.6667)
    },
    color() {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
    },

    currentTitle() {
      switch (this.step) {
        case 1: return 'Bitte E-Mail und Passwort eingeben'
        case 2: return 'Vergebe ein Passwort'
        case 3: return 'Danke! Schön, dass du dabei bist.'
        default: return ''
      }
    },

    // ...mapGetters(["getConfig"])
  },

  watch: {
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
  },

  methods: {

    validatePassword(value) {
      // let difference = 8 - value.length;
      if (value.length < 6) {
        this.passwordIsValid = !this.passwordIsValid
      }
    },

    registerUser(e) {
      this.loading = true
      var payload = APIService.getFormData(APIService.getForm(e))
      payload.active = true

      this.$store.dispatch('post',
        {
          endpoint: '/api/v1/users',
          event: payload,
          header: false,
          message: false
        }
      ).then(() => {
        this.succesfullCreated = true;
        payload.app = btoa(`${this.$store.state.main.config.result.licence}:`);
        this.$store.dispatch('auth/login', payload)
        this.loading = false;
        this.step = 3
        // APIService.setResponse({ message: 'test', color: "blue" });
      }).catch((error) => {
        const { response: { status } } = error;
        this.loading = false;
        if (status === 422) {
          APIService.setResponse({ message: "Die E-Mailadresse ist bereits vergeben", color: "red" });
          this.step_inside = 1
        }
        else {
          APIService.setResponse({ message: "Der E-Mailserver ist inaktiv", setRegisterError: true, color: "red" });
        }
      })
    },

    linkTo(route) {
      this.$router.push('/' + route)
    },

    imageSource(item) {
      let src = '';
      if (item != null) {
        src = APIService.getApiHost() + `/${item.logo}?width=250&height=250`;
      }
      return src;
    },

  },

  mounted() {
    this.$store.dispatch('fetch', { endpoint: `/api/v1/providers`, state: 'providers' })
    this.$store.dispatch('fetch', { endpoint: `/api/v1/plans`, state: 'plans', header: false })
  }
}
</script>

<style lang="sass" scoped>

  ::marker
    color: black
    font-size: 0.2em

  .hideIt
    display: none
  .click_copy
    cursor: copy
  .click_cursor
    cursor: pointer
  .chevron-left
    font-size: 0.75rem
  .fade-enter-active, .fade-leave-active
    transition: opacity .5s

  .fade-in-1s
    animation: fadeIn 1s
    -webkit-animation: fadeIn 1s
    -moz-animation: fadeIn 1s
    -o-animation: fadeIn 1s
    -ms-animation: fadeIn 1s

  .fade-in-2s
    animation: fadeIn 2s
    -webkit-animation: fadeIn 2s
    -moz-animation: fadeIn 2s
    -o-animation: fadeIn 2s
    -ms-animation: fadeIn 2s

  @keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  @-moz-keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  @-webkit-keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  @-o-keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  @-ms-keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  .fade-out-1s
    animation: fadeOut 1s
    -webkit-animation: fadeOut 1s
    -moz-animation: fadeOut 1s
    -o-animation: fadeOut 1s
    -ms-animation: fadeOut 1s

  .fade-out-2s
    animation: fadeOut 2s
    -webkit-animation: fadeOut 2s
    -moz-animation: fadeOut 2s
    -o-animation: fadeOut 2s
    -ms-animation: fadeOut 2s

  @keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0

  @-moz-keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0

  @-webkit-keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0

  @-o-keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0

  @-ms-keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0


  
  @media screen and (max-width: 860px)

    .login-box
      top: 55%
      width: 350px



  @media only screen and (max-width: 600px)
    #loginCard
      margin-top: 5% !important

  </style>
