  <template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" sm="3" class="ma-0 pa-0">
        <v-card flat>
          <div class="pa-4 my-0">
            <div class="ma-4">
              <div class="d-flex justify-center">
                <v-hover v-slot="{ hover }">
                  <v-img
                    max-width="111px"
                    height="111px"
                    :src="imageSource(oneUser, 100, 100)"
                    style="border-radius: 100px"
                  >
                    <template v-slot:placeholder>
                      <v-icon>mdi-camera</v-icon>
                    </template>
                    <v-file-input
                      v-if="hover"
                      class="justify-center mt-6 ml-3"
                      prepend-icon="mdi-camera"
                      hide-input
                      v-model="profileImg"
                      @change="profilBildUpload()"
                      show-size
                    />
                  </v-img>
                </v-hover>
              </div>
              <div class="d-flex justify-center">
                <v-chip
                  class="mt-2"
                  :color="currentUser(['ROLE_END_USER']) ? 'blue' : 'red'"
                  round
                  outlined
                  small
                >
                  {{ currentUser(["ROLE_END_USER"]) ? "User" : "Admin" }}
                </v-chip>
              </div>
              <div class="d-flex justify-center mt-4">
                <v-dialog
                  v-model="userNameDialog"
                  transition="dialog-top-transition"
                  max-width="360"
                >
                  <template v-slot:activator="{ on: dialog }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <div
                          v-on="{ ...tooltip, ...dialog }"
                          class="click_cursor"
                        >
                          <span class="font-weight-bold headline">{{
                            oneUser.name
                          }}</span>
                          <v-icon
                            color="primary"
                            class="showIcon mt-n1 ml-2"
                            size="18"
                          >
                            mdi-pencil
                          </v-icon>
                        </div>
                      </template>
                      <span>Name ändern</span>
                    </v-tooltip>
                  </template>

                  <v-card outlined>
                    <v-card-title class="text-h5 pb-2">
                      Name ändern
                    </v-card-title>
                    <v-card-text class="pb-0">Vor- und Nachname</v-card-text>
                    <v-form
                      @submit.prevent="
                        editUserProfile({ name: oneUser.name }),
                          (userNameDialog = false)
                      "
                    >
                      <v-card-text class="px-6">
                        <v-text-field
                          label="Dein Vor- und Nachname"
                          v-model="oneUser.name"
                          single-line
                          outlined
                          dense
                        />
                        <div class="d-flex justify-end">
                          <v-btn color="green" outlined small type="submit">
                            Speichern
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <v-divider class="my-4 mx-4"></v-divider>
            <!-- Persönliche Informationen -->
            <div>
              <v-row class="mt-0 mx-2">
                <!-- Email -->
                <v-col cols="12" md="12">
                  <p class="font-weight-normal grey--text pa-0 ma-0">E-Mail</p>
                  <v-dialog
                    v-model="userMailDialog"
                    transition="dialog-top-transition"
                    max-width="360"
                  >
                    <template v-slot:activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div
                            v-on="{ ...tooltip, ...dialog }"
                            class="click_cursor"
                          >
                            <span class="font-weight-bold">{{
                              get("auth_user").email
                            }}</span>
                            <v-icon
                              color="primary"
                              class="showIcon mt-n1 ml-2"
                              size="18"
                            >
                              mdi-pencil
                            </v-icon>
                          </div>
                        </template>
                        <span>E-Mail ändern</span>
                      </v-tooltip>
                    </template>

                    <v-card outlined>
                      <v-card-title class="text-h5 pb-2">
                        E-Mail ändern
                      </v-card-title>
                      <v-card-text class="pb-0"
                        >Wie lautet deine E-Mailadresse?</v-card-text
                      >
                      <v-form
                        @submit.prevent="
                          editUserMail(
                            get('auth_user').id,
                            get('auth_user').email
                          ),
                            (userMailDialog = false)
                        "
                        v-model="valid.email"
                      >
                        <v-card-text class="px-6">
                          <v-text-field
                            label="John@Liox.io"
                            v-model="get('auth_user').email"
                            single-line
                            outlined
                            dense
                            :rules="eMailRules"
                          ></v-text-field>
                          <div class="d-flex justify-end">
                            <v-btn
                              color="green darken-1"
                              outlined
                              small
                              type="submit"
                              :disabled="!valid.email"
                            >
                              Speichern
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="12" md="12" class="pt-0">
                  <p class="font-weight-normal grey--text pa-0 ma-0">
                    Passwort
                  </p>
                  <v-dialog
                    v-model="userPWDialog"
                    transition="dialog-top-transition"
                    max-width="360"
                  >
                    <template v-slot:activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div
                            v-on="{ ...tooltip, ...dialog }"
                            class="click_cursor d-flex justify-space-between"
                          >
                            <span class="mr-2">●●●●●●●●●●●●●●●●●</span>
                            <v-icon
                              color="primary"
                              class="mt-n1 ml-2"
                              size="18"
                            >
                              mdi-pencil
                            </v-icon>
                          </div>
                        </template>
                        <span>Passwort ändern</span>
                      </v-tooltip>
                    </template>

                    <v-card outlined>
                      <v-card-title class="text-h5 pb-2">
                        Passwort ändern
                      </v-card-title>
                      <v-card-text class="pb-0"
                        >Vergib ein sicheres Passwort aus mindestens 6
                        Zeichen.</v-card-text
                      >
                      <v-form
                        @submit.prevent="editUserPwd(), (userPWDialog = false)"
                        v-model="valid.password"
                      >
                        <v-card-text class="px-6">
                          <v-text-field
                            label="Neues Kennwort"
                            v-model="password"
                            :rules="passwordRules"
                            :append-icon="
                              showPwIcon
                                ? 'mdi-eye-outline'
                                : 'mdi-eye-off-outline'
                            "
                            @click:append="showPwIcon = !showPwIcon"
                            :type="showPwIcon ? 'text' : 'password'"
                            class="mt-2"
                            single-line
                            outlined
                          ></v-text-field>
                          <div class="d-flex justify-end">
                            <v-btn
                              color="green darken-1"
                              outlined
                              small
                              type="submit"
                              :disabled="!valid.password"
                            >
                              Speichern
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="12" md="12" class="pt-0">
                  <p class="font-weight-normal grey--text pa-0 ma-0">
                    Geburtsdatum
                  </p>
                  <v-dialog
                    v-model="userBirthDayDialog"
                    transition="dialog-top-transition"
                    max-width="360"
                    persistent
                  >
                    <template v-slot:activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div
                            v-on="{ ...tooltip, ...dialog }"
                            class="click_cursor"
                          >
                            <span class="mr-2 font-weight-bold">{{
                              formDate(oneUser.birthday)
                            }}</span>
                            <v-icon
                              color="primary"
                              class="mt-n1 ml-2"
                              size="18"
                            >
                              mdi-pencil
                            </v-icon>
                          </div>
                        </template>
                        <span>Geburtsdatum ändern</span>
                      </v-tooltip>
                    </template>

                    <v-card outlined>
                      <v-card-title class="text-h5 pb-2">
                        Geburtsdatum ändern
                      </v-card-title>
                      <v-card-text class="pb-0"
                        >Wann hast du Geburtstag?</v-card-text
                      >
                      <v-form
                        @submit.prevent="submitBirthday"
                        v-model="valid.birthdate"
                      >
                        <v-card-text class="px-6">
                          <v-menu
                            ref="birthdateMenu"
                            v-model="birthdateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="birthday"
                                label="Wann hast du Geburtstag?"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="birthdayRules"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="birthday"
                              :active-picker.sync="activePicker"
                              min="1910-01-01"
                              :max="new Date().toISOString().substr(0, 10)"
                              @change="birthdateMenu = false"
                            ></v-date-picker>
                          </v-menu>
                          <div class="d-flex justify-end">
                            <v-btn
                              color="green darken-1"
                              outlined
                              small
                              type="submit"
                              :disabled="!valid.birthdate"
                            >
                              Speichern
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </div>

            <!-- Adressdaten -->
            <!-- <div v-if="currentUser('ROLE_ADMIN') || currentUser('ROLE_TENANT')">
              <v-row class="mx-2">
                
                <v-col cols="12">
                  <div class="d-flex">
                    <p class="font-weight-normal grey--text pa-0 ma-0">
                      Straße & Hausnr.
                    </p>
                    <v-dialog
                      v-model="userAddressDialog"
                      transition="dialog-top-transition"
                      max-width="360"
                    >
                      <template v-slot:activator="{ on: dialog }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <div
                              v-on="{ ...tooltip, ...dialog }"
                              class="click_cursor"
                            >
                              <span class="mr-2 font-weight-bold">{{
                                get("webshop_user").address
                              }}</span>
                              <v-icon color="primary" size="18">
                                mdi-pencil
                              </v-icon>
                            </div>
                          </template>
                          <span>Straße ändern</span>
                        </v-tooltip>
                      </template>

                      <v-card outlined>
                        <v-card-title class="text-h5 pb-2">
                          Straße ändern
                        </v-card-title>
                        <v-card-text class="pb-0"
                          >In welche Straße wohnst du?</v-card-text
                        >
                        <v-form
                          @submit.prevent="
                            editWebShopUser({
                              address: get('webshop_user').address,
                            }),
                              (userAddressDialog = false)
                          "
                          v-model="valid.address"
                        >
                          <v-card-text class="px-6">
                            <v-text-field
                              class="mt-2"
                              label="Straße und Hausnummer"
                              placeholder="Straße und Hausnummer"
                              v-model="get('webshop_user').address"
                              single-line
                              required
                              outlined
                              :rules="addressRules"
                            />
                            <div class="d-flex justify-end">
                              <v-btn
                                color="green darken-1"
                                outlined
                                small
                                type="submit"
                              >
                                Speichern
                              </v-btn>
                            </div>
                          </v-card-text>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-col>

                <v-col cols="12">
                  <p class="font-weight-normal grey--text pa-0 ma-0">PLZ</p>
                  <v-dialog
                    v-model="userPLZDialog"
                    transition="dialog-top-transition"
                    max-width="360"
                  >
                    <template v-slot:activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div
                            v-on="{ ...tooltip, ...dialog }"
                            class="click_cursor"
                          >
                            <span class="mr-2 font-weight-bold">{{
                              get("webshop_user").zip
                            }}</span>
                            <v-icon
                              color="primary"
                              class="mt-n1 ml-2"
                              size="18"
                            >
                              mdi-pencil
                            </v-icon>
                          </div>
                        </template>
                        <span>PLZ ändern</span>
                      </v-tooltip>
                    </template>

                    <v-card outlined>
                      <v-card-title class="text-h5 pb-2">
                        PLZ ändern
                      </v-card-title>
                      <v-card-text class="pb-0"
                        >Wie lautet die Postleitzahl?</v-card-text
                      >
                      <v-form
                        @submit.prevent="
                          editWebShopUser({
                            town: get('webshop_user').zip,
                          }),
                            (userPLZDialog = false)
                        "
                      >
                        <v-card-text class="px-6">
                          <v-text-field
                            class="mt-2"
                            label="Postleitzahl"
                            placeholder="59065"
                            v-model="get('webshop_user').zip"
                            single-line
                            required
                            outlined
                          />
                        </v-card-text>
                        <div class="d-flex justify-end">
                          <v-btn
                            color="green darken-1"
                            outlined
                            small
                            type="submit"
                          >
                            Speichern
                          </v-btn>
                        </div>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row class="mt-0 mx-2">
                <v-col cols="12" md="6">
                  <p class="font-weight-normal grey--text pa-0 ma-0">Stadt</p>
                  <v-dialog
                    v-model="userTownDialog"
                    transition="dialog-top-transition"
                    max-width="360"
                  >
                    <template v-slot:activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div
                            v-on="{ ...tooltip, ...dialog }"
                            class="click_cursor"
                          >
                            <span class="mr-2 font-weight-bold">{{
                              get("webshop_user").town
                            }}</span>
                            <v-icon
                              color="primary"
                              class="mt-n1 ml-2"
                              size="18"
                            >
                              mdi-pencil
                            </v-icon>
                          </div>
                        </template>
                        <span>Stadt ändern</span>
                      </v-tooltip>
                    </template>

                    <v-card outlined>
                      <v-card-title class="text-h5 pb-2">
                        Stadt ändern
                      </v-card-title>
                      <v-card-text class="pb-0">Woher kommst du?</v-card-text>
                      <v-form
                        @submit.prevent="
                          editWebShopUser({
                            town: get('webshop_user').town,
                          }),
                            (userTownDialog = false)
                        "
                      >
                        <v-card-text class="px-6">
                          <v-text-field
                            class="mt-2"
                            label="Wohnort"
                            placeholder="Dortmund"
                            v-model="get('webshop_user').town"
                            single-line
                            required
                            outlined
                          />
                        </v-card-text>
                        <div class="d-flex justify-end">
                          <v-btn
                            color="green darken-1"
                            outlined
                            small
                            type="submit"
                          >
                            Speichern
                          </v-btn>
                        </div>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="12" md="6">
                  <p class="font-weight-normal grey--text pa-0 ma-0">Land</p>
                  <v-dialog
                    v-model="userCountryDialog"
                    transition="dialog-top-transition"
                    max-width="360"
                  >
                    <template v-slot:activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <div
                            v-on="{ ...tooltip, ...dialog }"
                            class="click_cursor"
                          >
                            <span class="mr-2 font-weight-bold">{{
                              get("webshop_user").country
                            }}</span>
                            <v-icon
                              color="primary"
                              class="mt-n1 ml-2"
                              size="18"
                            >
                              mdi-pencil
                            </v-icon>
                          </div>
                        </template>
                        <span>Land ändern</span>
                      </v-tooltip>
                    </template>

                    <v-card outlined>
                      <v-card-title class="text-h5 pb-2">
                        Land ändern
                      </v-card-title>
                      <v-card-text class="pb-0"
                        >Aus welchem Land kommst Du?</v-card-text
                      >
                      <v-form
                        @submit.prevent="
                          editWebShopUser({
                            town: get('webshop_user').country,
                          }),
                            (userCountryDialog = false)
                        "
                      >
                        <v-card-text class="px-6">
                          <v-text-field
                            class="mt-2"
                            label="Land"
                            placeholder="Deutschland"
                            v-model="get('webshop_user').country"
                            single-line
                            required
                            outlined
                          />
                        </v-card-text>
                        <div class="d-flex justify-end">
                          <v-btn
                            color="green darken-1"
                            outlined
                            small
                            type="submit"
                          >
                            Speichern
                          </v-btn>
                        </div>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </div> -->
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4" class="ml-2 pa-0">
        <div class="px-4">
          <h2 class="mb-2">Mitgliedschaft</h2>
          <!-- <p class="body-1">Abo-Details</p> -->
          <v-card width="100%" outlined>
            <v-system-bar
              color="primary darken-2"
              height="7"
              dark
            ></v-system-bar>
            <div>
              <v-card-title>
                <div
                  class="d-flex align-center justify-space-between"
                  style="width: 100%"
                >
                  {{ get("subscription").plan.name }}-Abo
                  <v-spacer></v-spacer>
                  <v-chip outlined label small>
                    Aktiv seit: {{ formDate(get("user").created) }}
                  </v-chip>
                </div>
              </v-card-title>
              <v-card-subtitle class="body-1">
                {{ get("subscription").plan.description }}
              </v-card-subtitle>
              <v-divider class="mx-4 my-1"></v-divider>
              <v-btn text class="mb-2 mx-4 pa-0" small disabled
                >Abo ändern</v-btn
              >
            </div>
          </v-card>
          <h3 class="mt-6 mb-2">Zahlungsdetails</h3>
          <v-card outlined width="100%">
            <div>
              <v-card-title class="pt-4">Nächste Zahlung: </v-card-title>
              <v-card-subtitle class="body-1">
                {{
                  formDate(
                    getNextBillingDate(
                      get("subscription").payment.transaction_date,
                      "y",
                      30
                    )
                  )
                }}
              </v-card-subtitle>
              <div class="d-flex px-4">
                <v-img
                  v-if="get('subscription').payment.card_type === 'MASTERCARD'"
                  max-height="25px"
                  max-width="40px"
                  src="@/assets/icons/MASTERCARD.png"
                >
                </v-img>
                <v-img
                  v-else
                  max-height="25px"
                  max-width="40px"
                  src="@/assets/icons/VISA.png"
                ></v-img>
                <p class="ml-4">
                  {{ get("subscription").payment.first_name }}
                  {{ get("subscription").payment.last_name }}
                </p>
              </div>
            </div>
          </v-card>
          <v-btn
            width="100%"
            color="red"
            small
            class="my-4"
            outlined
            @click="cancelSubscription(get('subscription').id)"
          >
            Mitgliedschaft kündigen
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

  <script>
import kontoLoeschen from "@/components/modal/kontoLoeschen";
import userRole from "@/services/userRoleService";
import { mapGetters } from "vuex";
import APIService from "@/services/api.service";
import AppService from "@/services/app.service";
import moment from "moment";

// Import um "extenden" zu können
import userDetails from "@/components/profile/userDetails";

export default {
  name: "userDetails",
  extends: userDetails,

  components: {
    subscription: () => import("../profile/subscription"),
  },

  data() {
    return {
      email: "",
      birthdateMenu: false,
      birthdate: "",
      birthday: "",
      street: "",
      zipCode: "",
      phone: "",
      city: "",
      activePicker: null,
      password: "",
      cancelBtn: true,
      suspendLoader: true,
      subs: "",
      product: "",
      profileImg: [],
      fileRecordsForUpload: [],
      certificates: [],
      profil_tabs: null,
      userNameDialog: false,
      userMailDialog: false,
      userPWDialog: false,
      userBirthDayDialog: false,
      userAddressDialog: false,
      userCountryDialog: false,
      userPLZDialog: false,
      userTownDialog: false,
      userPhoneDialog: false,
      showPwIcon: false,
      descriptionVorschau: false,
      selectedProfileItem: 0,
      valid: {
        email: false,
        password: false,
        name: false,
        birthdate: false,
        address: false,
        country: false,
        zip: false,
        town: false,
        phone: false,
      },

      eMailRules: [
        (v) => !!v || "Die E-Mailadresse ist erforderlich.",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Falsches Format für eine E-Mailadresse",
      ],

      passwordRules: [
        (v) => !!v || "Bitte Passwort eingeben",
        (v) => (v && v.length >= 6) || "Das Passwort ist zu kurz",
      ],

      userNameRules: [
        (v) => !!v || "Bitte Namen eingeben",
        (v) =>
          (v && v.length <= 30) ||
          "Der Name darf nicht mehr als 30 Zeichen haben.",
        (v) =>
          (v && v.length >= 2) || "Der Name muss mindestens 2 Zeichen haben.",
      ],

      addressRules: [
        (v) => !!v || "Bitte Adresse eingeben",
        (v) =>
          /^[a-zA-ZäöüÄÖÜß\s]+\s?\d+[a-zA-Z]?$/.test(v) ||
          "Falsches Format für eine deutsche Adresse",
      ],

      zipRules: [
        (v) => !!v || "Preis darf nicht leer sein",
        (v) =>
          (!isNaN(parseFloat(v)) && !isNaN(v)) ||
          "Postleitzahl kann nur Zahlen enthalten",
        (v) => parseFloat(v) >= 0 || "Postleitzahl erfordert eine Eingabe",
      ],
      content: "<h1>Initialer Kontent<h1>",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
    };
  },

  computed: {
    ...mapGetters(["get"]),
    oneUser: {
      get() {
        return this.$store.state.main.user.result;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    authUser: {
      get() {
        if (typeof this.$store.state.main.auth_user.result !== "undefined")
          return this.$store.state.main.auth_user.result;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date.toISOString().split("T")[0];
    },
    birthdayRules() {
      return [
        (v) => !!v || "Bitte Geburtsdatum eingeben",
        (v) => {
          const birthDate = new Date(this.birthday);
          const today = new Date();
          var age = today.getFullYear() - birthDate.getFullYear();
          var monthDiff = today.getMonth() - birthDate.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age >= 18 || "Sie müssen mindestens 18 Jahre alt sein";
        },
      ];
    },
    isSubscription: {
      get() {
        if (typeof this.$store.state.main.products.result !== "undefined")
          return subscription;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    birthdateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  methods: {
    isTester() {
      return AppService.isTester();
    },
    isCreatorSubActive() {
      return AppService.isCreatorSubActive();
    },

    isConsumerSubActive() {
      return AppService.isConsumerSubActive();
    },

    getNextBillingDate(transactionDate, recurringStatus, recurringPeriod) {
      return AppService.getNextBillingDate(
        transactionDate,
        recurringStatus,
        recurringPeriod
      );
    },

    add30Days(startDate) {
      return AppService.add30Days(startDate);
    },

    upgradePlan(name) {
      this.$store.dispatch("fetch", {
        endpoint: `/api/v1/products`,
        host: "getWebshopHost",
        state: "products",
      });
      this.$store.dispatch("fetch", {
        endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`,
        host: "getWebshopHost",
        state: "product",
      });
      this.$store.dispatch("modal/open", name);
    },

    sevenDaysOnTop(date) {
      // Startdatum als String
      const startDate = date;
      // Startdatum in Date-Objekt konvertieren
      const startDateObj = new Date(startDate);
      // 31 Tage hinzufügen
      startDateObj.setDate(startDateObj.getDate() + 7);
      // Enddatum in String konvertieren
      const endDate = startDateObj.toISOString().split("T")[0];
      // console.log(endDate);
      return this.formDate(endDate, "DD.MM.YYYY");
    },

    daysLeft(startDate, timeRange) {
      // Startdatum in Date-Objekt konvertieren
      const startDateObj = new Date(startDate);

      // Heutiges Datum
      const today = new Date();
      // Anzahl der verbleibenden Tage berechnen
      const daysLeft = Math.ceil(
        (today - startDateObj) / (1000 * 60 * 60 * 24)
      );

      // Prüfen, ob der Zeitraum innerhalb des angegebenen Zeitraums liegt
      if (daysLeft < timeRange) {
        var dayCount = (100 / timeRange) * daysLeft;
        console.log("innerhalb timeRange auf Userdetails", timeRange);
        console.log("innerhalb dayCount auf Userdetails", dayCount);
        return dayCount;
      } else {
        console.log("ausserhalb daysLeft auf Userdetails", daysLeft);
        return 100;
      }
    },

    editWebShopUser(object) {
      this.$store
        .dispatch("put", {
          endpoint: `/api/v1/users/${this.$store.state.main.webshop_user.result.id}`,
          event: object,
          host: "getWebshopHost",
          message: false,
        })
        .then(() => {
          this.$store.dispatch("fetch", {
            endpoint: `/api/v1/users/me`,
            host: "getWebshopHost",
            state: "webshop_user",
          });
          APIService.setResponse({
            message: "Änderung gespeichert.",
            color: "green",
          });
        });
    },

    editUserProfile(object) {
      this.$store
        .dispatch("patch", {
          endpoint: `/api/v1/users/${this.oneUser.id}`,
          event: object,
          message: false,
        })
        .then(() => {
          APIService.setResponse({
            message: "Änderung gespeichert.",
            color: "green",
          });
          this.$store.dispatch("fetch", {
            endpoint: `/api/v1/users/${this.oneUser.id}`,
            state: "user",
          });
        });
    },

    editUserMail(id, email) {
      if (this.authUser.email.length > 0) {
        this.$store
          .dispatch("patch", {
            endpoint: `/api/v1/users/${this.authUser.id}`,
            event: { name: this.authUser.email },
            host: "getAuthHost",
            message: false,
          })
          .then(() => {
            APIService.setResponse({
              message: "Deine neue E-Mail ist gespeichert.",
              color: "green",
            });
          });
      } else {
        APIService.setResponse({
          message: "Keine Änderung vorgenommen.",
          color: "blue",
        });
      }
    },

    editUserPwd() {
      let payload = { email: this.authUser.email };

      // --------------------->
      // DAS MUSS GEÄNDERT WERDEN!!!
      // --------------------->
      if (this.password.length > 0) {
        payload = { password: this.password, email: this.authUser.email };
        this.$store
          .dispatch("patch", {
            endpoint: `/api/v1/users/${this.authUser.id}`,
            event: payload,
            host: "getAuthHost",
            message: false,
          })
          .then(() => {
            APIService.setResponse({
              message: "Das Passwort wurde geändert.",
              color: "green",
            });
          });
      } else {
        APIService.setResponse({
          message: "Keine Änderung vorgenommen.",
          color: "blue",
        });
      }
      // <---------------------
    },

    submitBirthday() {
      if (this.valid.birthdate) {
        const today = new Date();
        const birthDate = new Date(this.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }

        if (age >= 18) {
          this.editUserProfile({ birthday: this.birthday + " 00:00:00" });
          this.userBirthDayDialog = false;
        } else {
          // Handle case where user is under 18
          // For example, show an error message
          APIService.setResponse({
            message: "Du musst mindestens 18 Jahre alt sein",
            color: "red",
          });
        }
      }
    },

    linkTo(route) {
      this.$router.push("/" + route);
    },

    // linkTo(adress){
    //   window.open(adress, "_blank")
    //   // window.location.href = adress
    // },

    openWarning(name) {
      this.$store.dispatch("modal/open", name);
    },

    saveSocialMedia(e) {
      this.$store.dispatch("patch", {
        endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
        event: e,
      });
    },

    currentUser(roles) {
      return userRole.hasRole(roles);
    },

    formatCurrency(number) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(number);
    },

    formDate(value, format) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    },

    imgaSource(oneUser) {
      AppService.imageSource(oneUser, 100, 100);
    },

    imgSource(oneUser) {
      let src = "./assets/logo/LogoMark_blue_new.png";
      if (oneUser.image != null) {
        src = APIService.getApiHost() + `/${oneUser.image}`;
      }
      return src;
    },

    onBeforeDeleteImage: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        if (confirm("Das Bild wirklich löschen?")) {
          this.$refs.logo.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },

    save() {
      let payload = { email: this.authUser.email };
      if (this.password.length > 0) {
        payload = { password: this.password, email: this.authUser.email };
      }

      this.$store.dispatch("patch", {
        endpoint: `/api/v1/users/${this.authUser.id}`,
        event: payload,
        host: "getAuthHost",
      });

      // Namensänderung
      if (this.oneUser.name.length > 0) {
        this.$store
          .dispatch("patch", {
            endpoint: `/api/v1/users/${this.$store.state.main.user.result.id}`,
            event: { name: this.oneUser.name },
            message: false,
          })
          .then((response) => {
            APIService.setResponse({
              message: `Änderung erfolgreich`,
              color: "green",
            });
            APIService.setUser(response.result);
          });
      }
      this.$store.dispatch("modal/close", "profil");
    },

    formDate(value, format) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    },

    close(name) {
      this.$store.dispatch("modal/close", name);
    },

    profilBildUpload() {
      this.$store
        .dispatch("uploadFiles", {
          endpoint: `/api/v1/users/upload`,
          files: { image: this.profileImg },
          metadata: {},
          message: false,
        })
        .then(() => {
          this.$store.dispatch("fetch", {
            endpoint: `/api/v1/users/${this.oneUser.id}`,
            state: "user",
          });
          APIService.setResponse({
            message: `Dein neues Profilbild sieht toll aus.`,
            color: "green",
          });
        });
    },

    resetForm() {
      this.image = [];
      this.logo = [];
      this.fileRecordsForUpload = [];
    },

    imgUpload() {
      setTimeout(() => {
        this.$store
          .dispatch("uploadFiles", {
            endpoint: `/api/v1/files`,
            files: { file: this.certificates },
            message: false,
          })
          .then(() => {
            APIService.setResponse({
              message: `Upload erfolgreich.`,
              color: "green",
            });
          });
      }, 50);
    },

    cancelSubscription(subId) {},
  },
  created() {
    // var userSubs = this.$store.state.main.user?.result?.subscriptions[0].id
    // this.$store.dispatch('fetch', { endpoint: `/api/v1/subscriptions/${userSubs}/payment`, state: 'subscription'})
    this.$store
      .dispatch("fetch", {
        endpoint: `/api/v1/subscriptions/2/payment`,
        state: "subscription",
      })
      .then((response) => {
        this.$store.dispatch("setPayment", response.result.payment);
      });

    // this.$store.dispatch('fetch', { endpoint: `/api/v1/subscriptions`, state: 'subscription'})

    // this.$store.dispatch('fetch', { endpoint: item._links.payment.href, state: 'subscription'})
    // console.log("expiredWhat", expiredWhat)
    // this.$store.dispatch('fetch', {
    //   endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`,
    //   header: false,
    //   state: 'product',
    //   host: 'getWebshopHost'
    // })

    // this.$store.dispatch('fetch', {
    //     endpoint: `/api/v1/products/${this.$store.state.main.licence.result.product.crm_id}`,
    //     state: 'product',
    //     host: 'getWebshopHost'
    //   })
  },
};
</script>
