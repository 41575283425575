<template>
  <modal name="adminVideoBearbeiten">
    <div class="pa-6">
      <span class="headline primary--text">Video bearbeiten</span>
      <p class="title mt-4 mb-0">Details</p>
      <v-form
        v-on:submit.prevent
        @submit="saveVideoChange($event, get('video').id)"
        v-model="valid"
      >
        <!-- Titel des Videos -->
        <v-row class="mt-2">
          <v-col cols="4">
            <span class="body-1">Titel des Videos:</span>
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="Titel des Videos"
              v-model="get('video').title"
              name="title"
              placeholder="Video Titel"
              :rules="videoTitleRules"
              outlined
              required
              dense
              counter="52"
            />
          </v-col>
        </v-row>

        <!-- Beschreibung -->
        <v-row class="mt-2">
          <v-col cols="4">
            <span class="body-1">Beschreibung:</span>
          </v-col>
          <v-col cols="8">
            <v-textarea
              v-model="get('video').description"
              name="description"
              outlined
              :rules="videoDescriptionRules"
              counter="2000"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- Kategorie -->
        <v-row class="mt-2">
          <v-col cols="4">
            <span class="body-1">Kategorie:</span>
          </v-col>
          <v-col cols="8" class="px-3 ma-0">
            <form>
              <v-select
                v-if="get('video').categorie != null"
                name="categories"
                v-model="get('video').categorie.id"
                :items="get('categories')"
                item-text="name"
                item-value="id"
                label="Auswahl einer Kategorie"
                outlined
              ></v-select>
              <!-- :rules="[v => !!v || 'Bitte eine Kategorie auswählen']" -->

              <v-select
                v-else
                name="categories"
                :rules="[(v) => !!v || 'Bitte eine Kategorie auswählen']"
                v-model="categories"
                :items="get('categories')"
                item-text="name"
                item-value="id"
                label="Auswahl einer Kategorie"
                outlined
              ></v-select>
            </form>
          </v-col>
        </v-row>

        <!-- Altersfreigabe -->
        <v-row class="mt-2">
          <v-col cols="4">
            <span class="body-1">Altersfreigabe:</span>
          </v-col>
          <v-col cols="8">
            <v-select
              name="fsk"
              v-model="get('video').fsk"
              :items="fskItems"
              item-text="fsk"
              item-value="id"
              label="Auswahl Altersfreigabe"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="end" class="mr-1 mb-4">
          <v-btn text class="grey--text" @click="close('adminVideoBearbeiten')">
            abbrechen
          </v-btn>

          <v-btn
            class="green--text ml-4"
            outlined
            type="submit"
            :disabled="!valid"
          >
            Speichern
          </v-btn>
        </v-row>

        <v-divider class="mt-6 mb-4"></v-divider>

        <!-- Thumbnail -->
        <span class="title mt-0">Thumbnail</span>
        <v-row class="my-4">
          <v-col cols="4">
            <span class="body-1">Thumbnail hochladen:</span>
          </v-col>
          <v-col col="8">
            <VueFileAgent
              ref="image"
              :deletable="true"
              :meta="true"
              :accept="'image/*'"
              :maxSize="'30MB'"
              :maxFiles="1"
              :errorText="{
                type: 'Falscher Dateityp. Es sind nur Bilder erlaubt.',
                size: 'Dateien dürfen nicht größer als 3GB sein.',
              }"
              @beforedelete="onBeforeDeleteImage($event)"
              @delete="imageDeleted($event)"
              @change="thumbnailUpload(get('video').id)"
              v-model="image"
            >
              <!--  -->
              <template v-slot:file-preview-new>
                <div
                  key="new"
                  class="file-preview-wrapper grid-box-item grid-block file-preview-new"
                >
                  <span class="file-preview">
                    <div
                      style="
                        position: absolute;
                        top: 15px;
                        right: 0px;
                        bottom: 0px;
                        left: 0px;
                      "
                    >
                      <v-icon size="16px">mdi-image</v-icon>
                      <span class="help-text" style="color: '#00C3FF'"
                        >Bild per Drag &amp; Drop einfügen oder Ordner
                        durchsuchen</span
                      >
                    </div>
                  </span>
                </div>
              </template>
            </VueFileAgent>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>

        <!-- On Stage? -->
        <!-- Soll das Video auf der Plattform sichtbar sein? -->
        <span class="title my-2">Sichtbarkeit</span>
        <v-row class="mt-2">
          <v-col class="mb-n2">
            <span class="body-1">On Stage?</span>
            <span
              class="fas fa-check green--text pl-4"
              v-if="get('video').published == true"
            ></span>
            <p class="caption grey--text mr-12">
              Das Video ist für registrierte Nutzer sichtbar.
            </p>
          </v-col>
          <span class="caption pt-5 pr-4">nein / ja</span>
          <v-switch
            name="published"
            v-model="get('video').published"
            class="mr-4"
          ></v-switch>
          <!-- @change="stageVideo(get('video').id)" -->
        </v-row>

        <v-row>
          <v-col class="mb-n2">
            <span class="body-1">Veröffentlichen?</span>
            <span
              class="fas fa-check green--text pl-4"
              v-if="get('video').share == true"
            ></span>
            <p class="caption mt- grey--text">
              Das Video ist ausserhalb der Mediathek erreichbar.<br />Es wird
              ein Link zum teilen und einbetten angezeigt.
            </p>
          </v-col>
          <span class="caption pt-5 pr-4">nein / ja</span>
          <v-switch v-model="get('video').share" name="share" class="mr-4" />
        </v-row>
        <!-- @change="shareItem(get('video')), publishVideo(get('video').id)" -->

        <div class="pa-6" v-if="get('video').share == true">
          <div>
            <p class="px-3 font-weight-bold">
              Code Snippet zum einbetten auf einer Webseite
            </p>
            <div class="d-flex justify-space-between">
              <code id="shareItem" class="mx-2 pa-4">{{
                shareItem(get("video"))
              }}</code>
              <v-btn
                @click="copyCodeSnippet(shareItem(get('video')))"
                small
                color="lightblue"
              >
                <v-icon size="16" class="mr-2">mdi-content-copy</v-icon>Kopieren
              </v-btn>
            </div>
          </div>

          <div class="mt-4">
            <p class="px-3 font-weight-bold">Link teilen</p>
            <div class="d-flex pl-3 mt-4 justify-space-between">
              <p class="">{{ shareLink(get("video")) }}</p>

              <v-btn
                @click="copyCodeSnippet(shareLink(get('video')))"
                small
                color="lightblue"
              >
                <v-icon size="16" class="mr-2">mdi-content-copy</v-icon>Kopieren
              </v-btn>
            </div>
          </div>
        </div>

        <v-row class="mt-2">
          <v-col class="mb-n2">
            <span class="body-1">Kommentare freischalten?</span>
            <span
              class="fas fa-check green--text pl-4"
              v-if="get('video').comment == true"
            ></span>
            <p class="caption grey--text">
              Mit Freischaltung können registrierte Nutzer unter diesem Video
              Kommentare verfassen.
            </p>
          </v-col>
          <span class="caption pt-5 pr-4">nein / ja</span>
          <v-switch
            name="comment"
            v-model="get('video').comment"
            class="mr-4"
          ></v-switch>
        </v-row>

        <v-divider class="my-6"></v-divider>

        <v-card-actions class="mb-8">
          <v-row class="justify-end">
            <v-btn
              text
              class="grey--text"
              @click="close('adminVideoBearbeiten')"
              >abbrechen</v-btn
            >
            <v-btn
              class="green--text ml-4"
              outlined
              type="submit"
              :disabled="!valid"
              >Speichern</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-form>
    </div>
  </modal>
</template>

<script>
// import Modal from "./modal"

import APIService from "@/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "adminVideoBearbeiten",
  components: {
    Modal: () => import("./modal"),
  },
  data() {
    return {
      title: "",
      hours: "",
      minutes: "",
      seconds: "",
      share: "",
      WhatsApp: "",
      published: "",
      categorie: null,
      categories: [],
      image: [],
      fileRecordsForUpload: [],
      fileRecords: [],
      fskItems: [
        { id: 0, fsk: "Ohne Altersbeschränkung" },
        { id: 6, fsk: "Ab 6 Jahren" },
        { id: 12, fsk: "Ab 12 Jahren" },
        { id: 16, fsk: "Ab 16 Jahren" },
        { id: 18, fsk: "Ab 18 Jahren" },
      ],
      valid: false,
      videoTitleRules: [
        (v) => !!v || "Bitte Titel vergeben",
        (v) =>
          (v && v.length <= 52) ||
          "Der Titel darf nicht mehr als 52 Zeichen haben.",
        (v) =>
          (v && v.length >= 2) || "Der Titel muss mindestens 2 Zeichen haben.",
      ],
      videoDescriptionRules: [
        (v) => !!v || "Bitte eine Beschreibung eingeben",
        (v) =>
          (v && v.length <= 2000) ||
          "Die Beschreibung darf nicht mehr als 350 Zeichen haben.",
        (v) =>
          (v && v.length >= 2) ||
          "Die Beschreibung muss mindestens 2 Zeichen lang sein.",
      ],
    };
  },

  computed: {
    ...mapGetters(["get"]),
  },

  methods: {
    thumbnailUpload(id) {
      setTimeout(() => {
        this.$store
          .dispatch("uploadFiles", {
            endpoint: `/api/v1/videos/${id}/upload`,
            files: { image: this.image },
            metadata: { width: 100, height: 100 },
            message: false,
          })
          .then((response) => {
            this.resetForm();
            APIService.setResponse({
              message: `Deine Änderungen wurde gespeichert.`,
              color: "blue",
            });
          })
          .catch((error) => {
            APIService.setResponse({ message: ``, color: "red" });
          });
      }, 100);
    },

    shareItem(item, style = 'height="500px" width="100%') {
      const src = `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
      let iframe = "";
      if (item.share) {
        iframe = `<iframe ${style}" src="${src}" title="${this.$store.state.main.config.result.site_name}"></iframe>`;
      }
      return iframe;
    },

    shareLink(item) {
      return `${window.location.protocol}//${window.location.host}/videos/${item.id}`;
    },

    publishVideo(id) {
      var published = this.$store.state.main.video.result.share;
      published != published;
      this.$store.dispatch("patch", {
        endpoint: `/api/v1/videos/${id}`,
        event: { share: published },
        message: false,
      });
    },

    stageVideo(id) {
      var staged = this.$store.state.main.video.result.published;
      staged != staged;
      this.$store.dispatch("patch", {
        endpoint: `/api/v1/videos/${id}`,
        event: { published: staged },
        message: false,
      });
    },

    copyCodeSnippet(item) {
      // var copyText = this.shareItem(this.$store.state.main.video.result)
      navigator.clipboard.writeText(item);
      APIService.setResponse({ message: "Kopiert.", color: "blue" });
    },

    close(name) {
      this.$store.dispatch("modal/close", name);
    },

    resetForm() {
      this.image = [];
      this.video = [];
      this.fileRecordsForUpload = [];
    },

    saveVideoChange(e, id) {
      const object = APIService.getFormData(APIService.getForm(e));
      object.fsk = parseInt(object.fsk);

      this.$store
        .dispatch("patch", {
          endpoint: `/api/v1/videos/${id}`,
          event: object,
          state: "video",
          message: false,
        })
        .then(() => {
          this.$store.dispatch("fetch", {
            endpoint: "/api/v1/videos?sort=DESC",
            state: "videos",
          });
          this.$store.dispatch("fetch", {
            endpoint: "/api/v1/categories",
            state: "categories",
          });
          this.$store.dispatch("modal/close", "adminVideoBearbeiten");
          APIService.setResponse({
            message: "Änderung gespeichert",
            color: "green",
          });
        })
        .catch((error) => {
          APIService.setResponse({
            message: "Vergib bitte einen Titel.",
            color: "red",
          });
        });
    },
  },
  created() {
    this.$store.dispatch("fetch", {
      endpoint: "/api/v1/categories",
      state: "categories",
    });
  },
};
</script>


<style lang="sass">
#shareItem code
	padding: 20px
	background-color: var(lightgrey)
	font-size: 12pt
	overflow: auto
	white-space: pre
</style>
