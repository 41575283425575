import APIService from "@/services/api.service";
import authHeader from "./auth-header";
import main from "@/store/main";
import moment from "moment";

class AppService {
  Starter = 1;
  Premium = 3;
  Enterprise = 15;
  PremiumAdopt = 16;

  getProducts(key) {
    const products = {
      Starter: [this.Starter, this.Premium, this.Enterprise, this.PremiumAdopt],
      Premium: [this.Premium, this.PremiumAdopt, this.Enterprise],
      Enterprise: [this.Enterprise],
    };
    return products[key];
  }

  isComponentExtended(component) {
    const components = this.getFromLocalStorage("components");
    let extended = false;
    components.forEach((item) => {
      if (item === component) {
        extended = true;
      }
    });
    return extended;
  }

  extendCoreComponents(components) {
    const existingComponents =
      JSON.parse(localStorage.getItem("components")) || [];
    components.forEach((component) => {
      if (!existingComponents.includes(component)) {
        existingComponents.push(component);
      }
    });
    localStorage.setItem("components", JSON.stringify(existingComponents));
  }

  getFromLocalStorage(key) {
    let result = [];
    const data = JSON.parse(localStorage.getItem(key));
    if (data) {
      return data;
    }
    return result;
  }

  runWarnHandler(app) {
    app.config.warnHandler = (msg, instance, trace) => {
      console.warn(`Meine Vue Warungen \n: ${msg}`, instance, trace);
    };
  }

  addLinks(app, elements) {
    //we found the app
    if (app._uid === 3) {
      elements.forEach((route) => {
        if (route.index > 0) {
          app.menuItems.splice(route.index, 0, {
            url: route.path,
            text: route.name,
            icon: route.icon,
            product: route.meta.product,
          });
        }
      });
    }
  }

  addContentLinks(app, elements) {
    //we found the app
    if (app._uid === 3) {
      elements.forEach((route) => {
        if (route.index > 0) {
          app.contentMenuItems.splice(route.index, 0, {
            url: route.path,
            text: route.name,
            icon: route.icon,
            product: route.meta.product,
          });
        }
      });
    }
  }

  addConsumerLinks(app, elements) {
    if (app._uid === 3) {
      elements.forEach((route) => {
        if (
          route.path.length > 0 &&
          !app.consumerLinks.some((link) => link.url === route.path)
        ) {
          app.consumerLinks.push({
            text: route.name,
            url: route.path,
            product: route.meta.product,
          });
        }
      });
    }
  }

  addComponents(router) {
    const routes = this.getFromLocalStorage("routes");
    routes.forEach((route) => {
      this.addAdminRoute(router, route);
    });
  }

  addRoutes(app, elements) {
    //we found the app
    if (app._uid === 3) {
      const routes = this.getFromLocalStorage("routes");
      elements.forEach((route) => {
        this.addAdminRoute(app.$router, route);
        if (!routes.some((item) => item.path === route.path)) {
          routes.push(route);
        }
      });
      localStorage.setItem("routes", JSON.stringify(routes));
    }
  }

  addAdminRoute(router, route) {
    const routeConfig = {
      path: route.path,
      name: route.name,
      meta: route.meta,
      component: () => import(`../../plugins/${route.component}`),
    };

    // Guard nur, wenn roles definiert sind.
    if (route.meta.roles) {
      routeConfig.beforeEnter = router.guard;
    }

    router.addRoute(routeConfig);
  }

  hasProduct(products) {
    return products.includes(main?.state?.licence?.result?.product?.crm_id);
  }

  // Consumer Subscription Active?
  // Sub 4 Content inside the App
  isConsumerSubActive() {
    return main.state.user?.result?.subscriptions.length > 0 ? true : false;
  }

  // Creator Subscription Active?
  // Sub 4 App
  isCreatorSubActive() {
    return main.state.licence?.result?.subscription;
  }

  isExplicit() {
    if (typeof main.state.config.result !== "undefined") {
      return main.state.config.result.explicit;
    }
    return;
  }

  isTester() {
    // const lic = this.getDataFromLocalStorage('licence');
    if (Object.keys(main.state.licence).length > 0) {
      const lic = main.state.licence.result;
      // Prüfen, ob der Zeitraum innerhalb des angegebenen Zeitraums liegt
      const daysLeft = this.howMuchDaysLeft(lic.created);
      const timeRange = 30;

      if (daysLeft <= timeRange) {
        // Innerhalb Zeitraum - Tester
        return true;
      } else {
        // Ausserhalb Zeitraum - Kein Tester
        return false;
      }
    }
  }

  log(string, input) {
    console.log(string, input);
  }

  isPaid(item, items = "images") {
    if (!main.state.user.result) {
      return false;
    }

    const paidOrders = main.state.user.result.orders;
    if (!paidOrders || !paidOrders.length) {
      return false;
    }

    if (items === "gallery") {
      return item.images.some((image) =>
        paidOrders.some((order) =>
          order.images.some((orderImage) => orderImage.id === image.id)
        )
      );
    }

    if (items === "images") {
      return paidOrders.some((order) =>
        order[items].some((image) => image.id === item.id)
      );
    }

    if (items === "videos") {
      return paidOrders.some((order) =>
        order[items].some((video) => video.id === item.id)
      );
    }

    return false; // If none of the conditions match, return false
  }

  isSubscription(item) {
    if (!main.state.user.result) {
      return false;
    }

    const subs = main.state.user.result.subscriptions;
    if (!subs || !subs.length) {
      return false;
    }

    return subs.some((sub) => {
      return item.plans.some((plan) => plan.id === sub.plan.id);
    });
  }

  formatPlans(object, state) {
    if (object.hasOwnProperty("plans")) {
      const numbersArray = object.plans.trim().split(",").map(Number);
      object.plans = [];
      for (let i = 0; i < numbersArray.length; i++) {
        if (isNaN(numbersArray[i])) {
          object.plans.push(main.state[state].result.plans[i].id);
        } else {
          object.plans.push(numbersArray[i]);
        }
      }
    }
    if (object.hasOwnProperty("categories")) {
      console.log("appService | FormatPlans", object);
      const numbersArray = object.categories.trim().split(",").map(Number);
      object.categories = [];
      console.log("appService | FormatPlans 1st", object);
      for (let i = 0; i < numbersArray.length; i++) {
        if (isNaN(numbersArray[i])) {
          object.categories.push(main.state[state].result.categories[i].id);
        } else {
          object.categories.push(numbersArray[i]);
          console.log("appService | FormatPlans 2nd", object);
        }
      }
    }
    return object;
  }

  getTimeElapsed(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const timeDiffMs = now.getTime() - dateTime.getTime();

    const secondsElapsed = Math.floor(timeDiffMs / 1000);
    const minutesElapsed = Math.floor(secondsElapsed / 60);
    const hoursElapsed = Math.floor(minutesElapsed / 60);
    const daysElapsed = Math.floor(hoursElapsed / 24);
    const monthsElapsed = Math.floor(daysElapsed / 30);
    const yearsElapsed = Math.floor(monthsElapsed / 12);

    if (secondsElapsed < 60) {
      return `vor ${secondsElapsed} ${
        secondsElapsed === 1 ? "Sekunde" : "Sekunden"
      }`;
    } else if (minutesElapsed < 60) {
      return `vor ${minutesElapsed} ${
        minutesElapsed === 1 ? "Minute" : "Minuten"
      }`;
    } else if (hoursElapsed < 24) {
      return `vor ${hoursElapsed} ${hoursElapsed === 1 ? "Stunde" : "Stunden"}`;
    } else if (daysElapsed < 30) {
      return `vor ${daysElapsed} ${daysElapsed === 1 ? "Tag" : "Tagen"}`;
    } else if (monthsElapsed < 12) {
      return `vor ${monthsElapsed} ${
        monthsElapsed === 1 ? "Monat" : "Monaten"
      }`;
    } else {
      return `vor ${yearsElapsed} ${yearsElapsed === 1 ? "Jahr" : "Jahren"}`;
    }
  }

  getNextBillingDate(transactionDate, recurringStatus, recurringPeriod) {
    // Return null if not recurring
    if (recurringStatus !== "y") {
      return "Endet bald";
    }

    const startDate = moment(transactionDate);
    const today = moment();
    const periodInDays = parseInt(recurringPeriod);

    // Calculate how many periods have passed
    const daysSinceStart = today.diff(startDate, "days");
    const periodsElapsed = Math.floor(daysSinceStart / periodInDays);

    // Calculate next billing date by adding one more period
    const nextBillingDate = startDate
      .clone()
      .add((periodsElapsed + 1) * periodInDays, "days");

    return nextBillingDate.format("YYYY-MM-DD");
  }

  howMuchDaysLeft(startDate) {
    // Startdatum in Date-Objekt konvertieren
    const startDateObj = new Date(startDate);
    // Heutiges Datum
    const today = new Date();
    // Anzahl der verbleibenden Tage berechnen
    // Calculate the number of days left in the subscription
    const daysLeft = Math.ceil((today - startDateObj) / (1000 * 60 * 60 * 24));

    // var dayCount = (100/timeRange) * daysLeft
    return daysLeft;
  }

  add30Days(startDate) {
    var startDateUTC = this.formUTCDate(startDate, "YYYY-DD-MM"); // Adjust the format if needed
    if (!startDateUTC) {
      throw new Error("ungültiges Datum");
    }

    const newDate = moment
      .utc(startDateUTC, "YYYY-DD-MM")
      .add(30, "days")
      .toDate();
    return this.formDate(newDate);
  }

  formUTCDate(value, format) {
    if (value) {
      return moment.utc(String(value)).format(format);
    }
  }

  formDate(value) {
    if (value) {
      return moment(String(value)).format("DD.MM.YYYY");
    }
  }

  isOwner(user) {
    return user.id == main.state.user.result.id;
  }

  hasRole(roles) {
    if (Object.keys(main.state.user).length > 0) {
      const user = main.state.user.result;
      if (roles.includes(user.role)) {
        return true;
      }
    }
    return false;
  }

  getFirstLetter(str) {
    const firstLetter = str.charAt(0);
    return firstLetter;
  }

  mouseOverCard(wrapperClass, cardClass) {
    const wrapper = document.querySelector(`${wrapperClass}`);
    const cards = document.querySelectorAll(`${cardClass}`);

    // console.log("wrapper", wrapper)
    // console.log("listener",wrapper.getAttribute('listener'))

    if (wrapper.getAttribute("listener") !== "true") {
      // console.log("wrapperClass", wrapperClass);
      wrapper.addEventListener("mousemove", function (e) {
        cards.forEach((card) => {
          const rect = card.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;

          card.style.setProperty("--xPos", `${x}px`);
          card.style.setProperty("--yPos", `${y}px`);
        });
      });
    }
  }

  checkVideoAccess(video) {
    if (video.price > 0) {
      return this.isPaid(video, "videos");
    }
    if (video.subscription) {
      return this.isSubscription(video);
    }
    return true;
  }

  checkImageAccess(image) {
    if (image.price > 0) {
      return this.isPaid(image, "videos");
    }
    if (image.subscription) {
      return this.isSubscription(image);
    }
    return true;
  }

  imageSource(path, width, height) {
    let src = "./assets/logo/LogoMark_blue_new.png";
    let size = "";

    if (path != null) {
      if (typeof height !== "undefined") {
        size = `width=${width}&height=${height - 0.1}`;
      }
      src = APIService.getApiHost() + `/${path}?${size}`;
    }
    return src;
  }

  imageSourceWithToken(path, width, height) {
    let src = "./assets/logo/LogoMark_blue_new.png";
    let size = "";
    const token = authHeader("access_token").Authorization;

    if (path != null) {
      if (typeof height !== "undefined") {
        size = `width=${width}&height=${height - 0.1}`;
      }
      src = APIService.getApiHost() + `/${path}?${size}&token=${token}`;
    }
    return src;
  }

  moveItem(array, fromIndex, toIndex) {
    var element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
  }

  indexAfter(videos, index) {
    index++;
    if (index >= videos.length) {
      index = 0;
    }
    return index;
  }

  indexBefore(videos, index) {
    index--;
    if (index < 0) {
      index = videos.length - 1;
    }
    return index;
  }

  addAfter(comp, items, index) {
    const oldIndex = index;
    const newindex = this.indexAfter(items, index);

    comp.savePosition(items[oldIndex].id, items[newindex].id).then(() => {
      this.moveItem(items, oldIndex, newindex);
    });
  }

  addBefore(comp, items, index) {
    const oldIndex = index;
    const newindex = this.indexBefore(items, index);

    comp.savePosition(items[oldIndex].id, items[newindex].id).then(() => {
      this.moveItem(items, oldIndex, newindex);
    });
  }

  getDurationAsString(mins) {
    const duration = mins.split(":");
    const hours = Math.floor(duration[0]);
    let seconds = Math.round(duration[2]);

    seconds = seconds < 10 ? "0" + seconds : seconds;

    if (hours > 0) {
      return `${hours}:${duration[1]}:${seconds}`;
    }
    return `${Math.floor(duration[1])}:${seconds}`;
  }

  linkTo(route, router) {
    router.push("/" + route).catch((error) => {
      APIService.setResponse({
        message: `Hier bist du bereits.`,
        color: "blue",
      });
    });
  }

  // acceptCookieBanner() {
  //   this.setCookie('cookieBanner', 'true');
  // }

  setCookie(name, value) {
    let cookie = name + "=" + encodeURIComponent(value);
    document.cookie = cookie;
  }

  getCookie(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  openCenteredPopup({ url, title, w, h }) {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width =
      window.innerWidth || document.documentElement.clientWidth || screen.width;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const newWindow = window.open(
      "about:blank",
      title,
      `scrollbars=yes,width=${w / systemZoom},height=${
        h / systemZoom
      },top=${top},left=${left}`
    );

    newWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>
            .loader {
              border: 5px solid #f3f3f3;
              border-radius: 50%;
              border-top: 5px solid #3498db;
              width: 50px;
              height: 50px;
              animation: spin 1s linear infinite;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -25px;
              margin-left: -25px;
            }
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          </style>
        </head>
        <body>
          <div class="loader"></div>
        </body>
      </html>
    `);

    newWindow.location = url;
    if (window.focus) newWindow.focus();
    return newWindow;
  }
}
export default new AppService();
